'use client'

import CarouselWrapper from 'src/modules/home/components/caroulsel-wrapper'
import Highlights from 'src/modules/home/components/highlights'
import Order from 'src/modules/home/components/order'

import useCity from '@Shared/hooks/use-city'

import InterstitialBanner from '@Components/ad-banner/interstitial-banner'
import UOLAdContainer from '@Components/ad-banner/uol-ads'
import AdBanners from '@Components/ad-banners'

import HighlightStatic from './components/highlight-static'

const HomePage = () => {
  const { city } = useCity()

  return (
    <main className="mkt__home" data-midia="ads_midia_page-home">
      <InterstitialBanner />
      <UOLAdContainer id="div-gpt-ad-background" className="h-0" />
      <div className="mkt__content min-h-[300px] pb-8">
        {/* TODO: remove city logic when festival is ended */}
        {city.uf === 'RJ' ? <HighlightStatic /> : <Highlights />}
        <div className="mx-auto my-0 max-w-screen-xl">
          <Order />
          <CarouselWrapper />
        </div>
        <AdBanners className="mx-auto my-0 flex max-w-screen-xl items-start" />
      </div>
    </main>
  )
}

export default HomePage
