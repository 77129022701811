const HighlightStatic = () => {
  return (
    <div id="highlight-banner" className="highlight-banner">
      <div className="mx-auto grid w-full max-w-screen-xl grid-cols-2 gap-4 px-4 pt-4 lg:grid-cols-[3fr_1.5fr]">
        <div className="relative h-[88px] rounded-lg first:col-span-2 first:h-[181px] min-[425px]:h-[145px] min-[425px]:first:h-[250px] lg:h-[170px] lg:first:col-span-1 lg:first:row-span-2 lg:first:h-[360px]">
          <div>
            <img
              alt="Imagem de capa do Festival do Rio"
              className="absolute top-0 size-full max-w-screen-xl rounded-lg object-cover"
              src="https://ingresso-a.akamaihd.net/catalogo/img/tag-page/banner-festival-do-rio-2024.jpg"
            />
            <a
              className="absolute z-10 flex size-full items-end rounded-lg lg:items-center"
              href="https://movieid.com/hotsite/festival-do-rio-2024"
            ></a>
          </div>
        </div>
        <div className="relative h-[88px] rounded-lg first:col-span-2 first:h-[181px] min-[360px]:h-[145px] min-[360px]:first:h-[250px] lg:h-[170px] lg:first:col-span-1 lg:first:row-span-2 lg:first:h-[360px]">
          <div className='after:absolute after:bottom-[-2px] after:left-0 after:block after:h-full after:w-full after:rounded-lg after:bg-gradient-to-t after:from-ing-neutral-600 after:to-transparent after:content-[""]'>
            <img
              alt="Imagem de capa do filme Coringa: Delírio A Dois"
              className="absolute top-0 size-full max-w-screen-xl rounded-lg object-cover"
              src="https://ingresso-a.akamaihd.net/prd/img/movie/coringa-delirio-a-dois/759db388-74d0-406e-a533-9a66704e0a70.webp"
            />
            <a
              className="absolute z-10 flex size-full items-end rounded-lg"
              href="https://www.ingresso.com/filme/coringa-delirio-a-dois"
            >
              <div className="pb-3 pl-3 sm:pb-5 sm:pl-5">
                <div className="flex flex-col md:flex-row"></div>
                <div className="hidden items-center lg:flex">
                  <div className="mr-1 flex items-baseline rounded-[3px] text-xs">
                    <div className="mr-1 flex items-baseline rounded-[3px] text-xs">
                      <div className="inline-flex h-6 w-6 items-center justify-center rounded bg-[#EC1D25] text-center text-[10px] font-bold uppercase text-white">
                        16
                      </div>
                    </div>
                  </div>
                  <span className="ml-2 text-xs leading-none md:text-[10px]">
                    Drama, Suspense
                  </span>
                </div>
                <h2 className="mt-2.5 line-clamp-1 text-base leading-none sm:text-xl lg:mt-4 lg:text-2xl">
                  Coringa: Delírio A Dois
                </h2>
                <div className="mr-5 hidden text-base lg:line-clamp-1">
                  Após os eventos do primeiro filme, Arthur Fleck está internado
                  em um hospital psiquiátrico. Ele é visitado por uma
                  psiquiatra, a Dra. Harleen Quinzel, que fica fascinada por sua
                  história. Juntos, eles exploram a mente de Arthur e os eventos
                  que o levaram a se tornar o Coringa.
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="relative h-[88px] rounded-lg first:col-span-2 first:h-[181px] min-[360px]:h-[145px] min-[360px]:first:h-[250px] lg:h-[170px] lg:first:col-span-1 lg:first:row-span-2 lg:first:h-[360px]">
          <div className='after:absolute after:bottom-[-2px] after:left-0 after:block after:h-full after:w-full after:rounded-lg after:bg-gradient-to-t after:from-ing-neutral-600 after:to-transparent after:content-[""]'>
            <img
              alt="Imagem de capa do filme A Forja – O Poder Da Transformação"
              className="absolute top-0 size-full max-w-screen-xl rounded-lg object-cover"
              src="https://ingresso-a.akamaihd.net/prd/img/movie/a-forja-o-poder-da-transformacao/66030644-61fd-4939-83a1-04e0115ffe19.webp"
            />
            <a
              className="absolute z-10 flex size-full items-end rounded-lg"
              href="https://www.ingresso.com/filme/a-forja-o-poder-da-transformacao"
            >
              <div className="pb-3 pl-3 sm:pb-5 sm:pl-5">
                <div className="flex flex-col md:flex-row"></div>
                <div className="hidden items-center lg:flex">
                  <div className="mr-1 flex items-baseline rounded-[3px] text-xs">
                    <div className="inline-flex h-6 w-6 items-center justify-center rounded bg-[#00A651] text-center text-[10px] font-bold uppercase text-white">
                      L
                    </div>
                  </div>
                  <span className="ml-2 text-xs leading-none md:text-[10px]">
                    Drama
                  </span>
                </div>
                <h2 className="mt-2.5 line-clamp-1 text-base leading-none sm:text-xl lg:mt-4 lg:text-2xl">
                  A Forja – O Poder Da Transformação
                </h2>
                <div className="mr-5 hidden text-base lg:line-clamp-1">
                  Um ano depois de encerrar o ensino médio, o jovem Isaías
                  Wright não tem planos para o futuro e é desafiado por sua mãe
                  solo e um empresário de sucesso a começar a traçar um rumo
                  melhor para sua vida. Ele passa a ser discipulado pelo seu
                  novo mentor, conta com orações de sua mãe e de uma guerreira
                  de orações, Dona Clara, e começa a descobrir o propósito de
                  Deus para sua vida.
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HighlightStatic
