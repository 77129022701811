import dayjs from 'dayjs'
import { ComponentProps } from 'react'
import Button from 'src/ui/button'
import Dialog from 'src/ui/dialog'

import { cn } from '@Shared/helpers/util'

import { Event } from '@Schemas/domain/event'

import tracking from '@Services/tracking'

import Image from '@Components/Image'
import Link from '@Components/Link'
import MovieTrailerModal from '@Components/modal-components/movie-trailer'
import ParentalRating from '@Components/parental-rating'
import Tag from '@Components/tag'

type HighlightProps = ComponentProps<'div'> & {
  movie: Event
  index: number
  isCarousel?: boolean
}

const Highlight = ({
  movie,
  index,
  isCarousel,
  className,
  ...props
}: HighlightProps) => {
  const handleTracking = () => {
    tracking.event({
      event: 'gaEventPush',
      event_category: 'Home Page',
      event_action: 'Click on Highlight Carousel',
      event_label: `event; ${movie?.title}; ${index}`
    })
  }

  const isCommingSoon = (premiereDate: string) => {
    const today = dayjs()
    const eventDate = premiereDate ? dayjs(premiereDate.slice(0, 10)) : ''

    return eventDate > today
  }

  const getBannerImage = () =>
    movie.images?.find((image) => image.type === 'PosterHorizontal')?.url ?? ''

  const hasTrailers = Boolean(movie.trailers.length)

  const isFirstItem = index === 0

  const Title = isCarousel || !isFirstItem ? 'h2' : 'h1'

  return (
    <div
      className={cn(
        isCarousel && 'relative before:rounded-none after:rounded-none',
        isFirstItem || isCarousel
          ? 'before:absolute before:bottom-0 before:left-[-2px] before:z-10 before:block before:h-full before:w-3/5 before:rounded-lg before:bg-gradient-to-r before:from-ing-neutral-700 before:to-ing-neutral-700/0 before:content-[""]'
          : 'after:absolute after:bottom-[-2px] after:left-0 after:block after:h-full after:w-full after:rounded-lg after:bg-gradient-to-t after:from-ing-neutral-600 after:to-transparent after:content-[""]',
        className
      )}
      {...props}
      onClick={handleTracking}
      data-testid="highlight"
    >
      <Image
        src={getBannerImage()}
        className={cn(
          'max-w-screen-xl rounded-lg object-cover',
          isCarousel && 'rounded-none'
        )}
        alt={`Imagem de capa do filme ${movie.title}`}
        fallback="/images/placeholder-movie-image.jpg"
        fill
        priority={isFirstItem}
      />
      <Link
        href={`/filme/${movie.urlKey}`}
        className={cn(
          'absolute z-10 flex h-full w-full items-end rounded-lg',
          (isFirstItem || isCarousel) && 'lg:items-center'
        )}
      >
        <div
          className={cn(
            'pb-3 pl-3 sm:pb-5 sm:pl-5',
            (isFirstItem || isCarousel) && 'pb-5 pl-5'
          )}
        >
          <div className="flex flex-col md:flex-row">
            {movie.inPreSale && (
              <Tag
                name="pré-venda"
                className="mb-2 w-fit last:mb-4 md:mb-0 md:mr-2"
                withColor="yellow"
              />
            )}
            {isCommingSoon(movie?.premiereDate?.localDate) && (
              <Tag
                className={cn(
                  'w-fit last:mb-4',
                  !isCarousel && !isFirstItem && 'hidden lg:flex'
                )}
                name={`ESTREIA ${movie?.premiereDate?.dayAndMonth}`}
                withColor="yellow"
              />
            )}
            {movie?.premiereDate?.isToday && (
              <Tag
                className={cn(
                  'w-fit last:mb-4',
                  !isCarousel && !isFirstItem && 'hidden lg:flex'
                )}
                name="ESTREIA HOJE"
                withColor="yellow"
              />
            )}
          </div>
          <div
            className={cn(
              'flex items-center',
              !isFirstItem && 'hidden lg:flex'
            )}
            data-testid="highlight-rating-genres"
          >
            <ParentalRating.Root className="mr-1 rounded-[3px]">
              <ParentalRating.Icon
                className="h-6 w-6 text-[10px]"
                rating={movie?.contentRating}
              />
            </ParentalRating.Root>

            <span className="ml-2 text-xs leading-none md:text-[10px]">
              {movie.genres && movie.genres.toString().replace(',', ', ')}
            </span>
          </div>
          <Title
            className={cn(
              'mt-2.5 line-clamp-1 text-base leading-none min-[360px]:line-clamp-2 sm:text-xl lg:mt-4 lg:text-2xl',
              (isFirstItem || isCarousel) &&
                'line-clamp-2 text-2xl sm:text-2xl lg:text-4xl'
            )}
          >
            {movie.title}
          </Title>
          <div
            className={cn(
              'mr-5 hidden text-base lg:line-clamp-1',
              (isFirstItem || isCarousel) &&
                'max-w-[600px] lg:mt-4 lg:line-clamp-3'
            )}
          >
            {movie.synopsis}
          </div>
        </div>
      </Link>
      <div
        className={cn(
          'hidden',
          (isFirstItem || isCarousel) &&
            'absolute bottom-0 z-20 gap-2 sm:pb-5 sm:pl-5 lg:flex'
        )}
        data-testid="highlight-buttons"
      >
        <Button asChild>
          <Link href={`/filme/${movie.urlKey}`} onClick={handleTracking}>
            Ingressos
          </Link>
        </Button>
        {hasTrailers && (
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button variant="outline">Assistir Trailer</Button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay />
              <MovieTrailerModal movie={movie} />
            </Dialog.Portal>
          </Dialog.Root>
        )}
      </div>
    </div>
  )
}

export default Highlight
