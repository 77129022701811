import { ComponentProps, Children } from 'react'

import { cn } from '@Shared/helpers/util'

type RootProps = ComponentProps<'div'>

const Root = ({ children, className, ...props }: RootProps) => {
  const childrenCount = Children.count(children)

  return (
    <div
      className={cn(
        'mx-auto grid w-full grid-cols-2 gap-4 lg:grid-cols-[3fr_1.5fr]',
        className,
        childrenCount === 1 && 'lg:block'
      )}
      {...props}
    >
      {children}
    </div>
  )
}

const Item = ({ className, ...props }: RootProps) => (
  <div
    className={cn(
      'relative h-[88px] rounded-lg first:col-span-2 first:h-[181px] min-[360px]:h-[145px] min-[360px]:first:h-[250px] lg:h-[170px] lg:first:col-span-1 lg:first:row-span-2 lg:first:h-[360px]',
      className
    )}
    {...props}
  />
)

const HighlightGrid = {
  Root,
  Item
}

export default HighlightGrid
